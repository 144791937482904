import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Image, { GatsbyImageProps } from 'gatsby-image';
import Styled from 'styled-components/macro';

import Button from 'components/buttons/Button';
import Content from 'components/layout/Content/Content';
import MediaQuery from 'components/layout/MediaQuery';
import Headline from 'components/texts/Headline';
import RichDescription from 'components/texts/RichDescription';
import Tagline from 'components/texts/Tagline';

const Container = Styled.div`
  margin: 0 0 9rem 0;
  overflow: hidden; // fix for columns overflow
  padding: 1.2rem 0;

  ${Media.greaterThan('medium')`
     margin: 0 0 18rem 0;
  `}
`;

const Columns = Styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${Media.greaterThan('700px')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

const Column = Styled.div`
  position: relative;
  
  ${Media.greaterThan('700px')`
    width: 45%;
  `}
`;

const ColumnIllustration = Styled(Column)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${Media.greaterThan('700px')`
    width: 49%;
  `}
`;

const Text = Styled.div`
  width: 43rem;
  max-width: 100%;

  ${Media.greaterThan('large')`
    width: calc(43rem + 9.5rem);
    padding-left: 4rem;
  `}
`;

const ImageMobile = Styled(Image)`
`;

const ImageDesktop = Styled(Image)`
  width: 100%;
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const ExtendedButton = Styled(Button)`
  margin-bottom: 5rem;
  margin-top: 3rem;

  ${Media.greaterThan('medium')`
    margin-bottom: 0;
  `}

  ${Media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const w = 750;
const h = 500;

const VideoContainer = Styled.div`
  position: relative;
  height: 0;
  width: ${w}px;
  max-width: 100%;
  padding-bottom: ${(props) => (props.videoH ? Math.round((props.videoH / w) * 100) : Math.round((h / w) * 100))}%;
  margin: 0 auto;
  box-shadow: 0px 2px 20px rgba(3,3,3,0.1);
  border-radius: 30px;
  overflow: hidden;
  background-color: #f8f8f8;
  
  ${Media.greaterThan('medium')`
    margin: 0;
  `}
`;

const ImgContainer = Styled.div`

`;

const VideoContent = Styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Video = Styled.video`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const NavButtonLink = Styled.a`
`;

type HomeWireframeProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  videoName: string;
  link?: string;
  description2?: string;
  description3?: string;
  tagAsTitle?: boolean;
  taglineh2?: string;
  videoH?: number;
};

export default ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  videoName,
  link,
  description2,
  description3,
  tagAsTitle,
  taglineh2,
  videoH,
}: HomeWireframeProps) => (
  <Container>
    <Content>
      <Columns>
        <ColumnIllustration>
          <VideoContainer videoH={videoH}>
            <VideoContent>
              <Video autoPlay muted loop playsInline>
                <source
                  src={`/videos/${videoName}.mp4`}
                  type="video/mp4"
                />
                <source
                  src={`/videos/${videoName}.ogv`}
                  type="video/ogv"
                />
                <source
                  src={`/videos/${videoName}.webm`}
                  type="video/webm"
                />
                <img
                  src={`/videos/${videoName}.png`}
                  alt={`Video of ${imageAlt}`}
                />
              </Video>
            </VideoContent>

            <ImgContainer>
              <MediaQuery lessThan="medium">
                <ImageMobile
                  fluid={'fluid' in imageB ? imageB.fluid : null}
                  alt={imageAlt}
                />
              </MediaQuery>

              <MediaQuery greaterThan="medium">
                <ImageDesktop
                  fluid={'fluid' in imageA ? imageA.fluid : ''}
                  alt={imageAlt}
                />
              </MediaQuery>
            </ImgContainer>
          </VideoContainer>
        </ColumnIllustration>

        <Column>
          <Text>
            {tagline && tagAsTitle && (
              <TaglineExtended as="h1">{tagline}</TaglineExtended>
            )}
            {taglineh2 && (
              <TaglineExtended as="h2">{taglineh2}</TaglineExtended>
            )}
            {tagAsTitle && <Headline as="h2">{headline}</Headline>}

            {tagline && !tagAsTitle && (
              <TaglineExtended>{tagline}</TaglineExtended>
            )}
            {!tagAsTitle && <Headline>{headline}</Headline>}

            {!description2 && !description3 && (
              <RichDescription content={description} />
            )}
            {description2 && !description3 && (
              <RichDescription content={`${description}<br/>${description2}`} />
            )}
            {description2 && description3 && (
              <RichDescription
                content={
                  `${description}<br/>${description2}<br/>${description3}`
                }
              />
            )}

            {link && (
              <NavButtonLink href={link}>
                <ExtendedButton variant="primarylight" solid>
                  Read more
                </ExtendedButton>
              </NavButtonLink>
            )}
          </Text>
        </Column>
      </Columns>
    </Content>
  </Container>
);
