import * as React from "react";
import Styled from "styled-components/macro";
import { graphql } from "gatsby";

import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import HeroBanner from "../../../components/pages/solutions/HeroBanner";
import HomeExamples from "../../../components/pages/home/HomeExamples";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import SectionWithRightVideoWireframe from "../../../components/pages/solutions/SectionWithRightVideoWireframe";
import SectionWithRightImage from "../../../components/pages/solutions/SectionWithRightImage";
import SectionWithLeftImage from "../../../components/pages/solutions/SectionWithLeftImage";
import SectionWithVerticalImage from "../../../components/pages/solutions/SectionWithVerticalImage";
import AiHeatmap from "../../../components/pages/ai/AiHeatmap";
import HomeWireframe from "../../../components/pages/home/HomeWireframe";
import Shoutout from "../../../components/pages/signup/Shoutout";
import Content from "../../../components/layout/Content/Content";
import HomeAutodesignerLeft from "../../../components/pages/home/HomeAutodesignerLeft";
import HomeAutodesigner from "../../../components/pages/home/HomeAutodesigner";

const ContentExtended = Styled(Content)`
  margin-top: 6rem;
`;

const Home = ({ data }) => {
  return (
    <Layout>
      <Meta
        title="Uizard For UX Professionals | Uizard"
        description="The best design tool for UX pros to design apps, websites, and UI systems."
        url="/solutions/ux-professionals/"
      />

      <HeroBanner
        tagline="Designers & UX Professionals"
        headline="Supercharge how you ideate and iterate"
        description="Test ideas, iterate on concepts, and collaborate with your team faster than ever. Uizard is here to change the way you and your team work on design projects."
        image={data.UXProsCoverBig.childImageSharp}
        imageSmall={data.UXProsCoverSmall.childImageSharp}
        imageAlt="ui design for mobile, web, and tablet"
      />

      <HomeAutodesignerLeft
        headline="Generate mockups in seconds from text prompts"
        description="Transform how you approach ideation with the power of generative AI."
        description2="Simply enter your app, web, or UI design idea in plain English and let AI do the hard work... the perfect launchpad for your next design project."
        imageA={data.autodesignerImageA.childImageSharp}
        imageB={data.autodesignerImageB.childImageSharp}
        imageAlt="turn text prompts into UI designs with AI"
        videoName="uizard-autodesigner-light"
        link="/autodesigner/"
      />

      <HomeAutodesigner
        headline="Adapt screenshots for instant inspiration"
        description="With a little bit of AI magic, Uizard Screenshot enables you to import screenshots of other apps or websites and transform them into fully editable designs."
        description2="Combine this with Uizard's easy-to-use editor, and you're minutes away from visualizing your next app design concept."
        imageA={data.hificonversionImageA.childImageSharp}
        imageB={data.hificonversionImageB.childImageSharp}
        imageAlt="turn static screenshots into editable UI mockups"
        videoName="uizard-scan-screenshot"
        link="/screenshot/"
        videoH={460}
      />

      <SectionWithVerticalImage
        headline="Prototype rapidly"
        description="Create <a:https://uizard.io/mockups/>UI mockups</a> and prototypes rapidly. Design screens 
        and UIs to tight deadlines, import your own assets with the click of a button, and test ideas faster than ever before."
        imageA={data.UXProsPrototypeRapidlyBig.childImageSharp}
        imageB={data.UXProsPrototypeRapidlySmall.childImageSharp}
        imageAlt="rapid ux prototyping"
      />

      <AiHeatmap
        headline="Review your designs with attention heatmaps"
        description="Use Uizard's Focus Predictor to instantly analyze your designs."
        description2="Validate your design assumptions before user testing. Find out where your prospective users' attention will be drawn with the click of a button."
        imageAlt="uizard ai heat map"
        imageA={data.heatmapImageA.childImageSharp}
        imageB={data.heatmapImageB.childImageSharp}
        videoName="uizard-heatmap-generation"
        link=""
      />

      <HomeWireframe
        headline="Adapt your design to your audience in seconds."
        description="Uizard can automatically transform your wireframes into beautiful, high-fidelity mockups or turn polished designs into wireframes in one click."
        imageA={data.wireframesToMockupsBig.childImageSharp}
        imageB={data.wireframesToMockupsSmall.childImageSharp}
        videoName="uizard-wireframe-high-fi-mode"
        imageAlt="Visual showing Uizard's features for rendering a design as a low-fi wireframe or as an high-fi mockup"
        videoW={600}
        videoH={600}
      />

      <SectionWithRightVideoWireframe
        headline="Scan your sketches"
        description="From pen and paper to digital design in seconds! Turn your rough sketches into polished designs with Uizard's AI design features. Simply scan your sketches and let the AI do the rest!"
        imageA={data.wireframeScanImageA.childImageSharp}
        imageB={data.wireframeScanImageB.childImageSharp}
        imageAlt="Image of demonstrating Uizard to scan hand-drawn sketches and transform them into beautiful designs automatically."
        videoName="uizard-wireframe-scan"
        videoH={520}
      />

      <SectionWithLeftImage
        headline="Real-time feedback"
        description="Stakeholder feedback; supercharged. With our smart sharing features, we are changing the way feedback works. Share a link to your project and wait for the comments and suggestions to roll in."
        imageA={data.UXProsRealTimeFeedbackBig.childImageSharp}
        imageB={data.UXProsRealTimeFeedbackSmall.childImageSharp}
        imageAlt="ui project feedback tools"
      />

      <SectionWithRightImage
        headline="Streamline user flows"
        description="Perfecting user flows has never been so simple. Update CTAs, add new pages, and link screens with ease. You can even share previews of your designs with team members or potential customers."
        imageA={data.UXProsUserFlowsBig.childImageSharp}
        imageB={data.UXProsUserFlowsSmall.childImageSharp}
        imageAlt="design ux user flows"
      />

      <HomeFeatured />
      <HomeExamples />

      <ContentExtended>
        <Shoutout
          headline="UI/UX Design made easy"
          description="Prototype at the speed of thought"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </ContentExtended>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    UXProsCoverBig: file(
      relativePath: { eq: "ux-professionals/UXPros_Cover_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1014, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsCoverSmall: file(
      relativePath: { eq: "ux-professionals/UXPros_Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 507, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsPrototypeRapidlyBig: file(
      relativePath: {
        eq: "ux-professionals/UXPros_PrototypeRapidly_PNG_2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsPrototypeRapidlySmall: file(
      relativePath: { eq: "ux-professionals/UXPros_PrototypeRapidly_PNG.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1070, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageA: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageB: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageA: file(
      relativePath: { eq: "ai/HifiConversion-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageB: file(
      relativePath: { eq: "ai/HifiConversion-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    heatmapImageA: file(relativePath: { eq: "ai/heatmap-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    heatmapImageB: file(relativePath: { eq: "ai/heatmap-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScanImageA: file(
      relativePath: { eq: "home/uizard-wireframe-scan-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScanImageB: file(
      relativePath: { eq: "home/uizard-wireframe-scan-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsDragandDropBig: file(
      relativePath: { eq: "ux-professionals/UXPros_DragandDrop_PNG_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 860, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsDragandDropSmall: file(
      relativePath: { eq: "ux-professionals/UXPros_DragandDrop_PNG.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 430, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsUserFlowsBig: file(
      relativePath: { eq: "ux-professionals/UXPros_UserFlows_PNG_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1152, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsUserFlowsSmall: file(
      relativePath: { eq: "ux-professionals/UXPros_UserFlows_PNG.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsRealTimeFeedbackBig: file(
      relativePath: { eq: "ux-professionals/UXPros_RealtimeFeedback_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1116, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsRealTimeFeedbackSmall: file(
      relativePath: { eq: "ux-professionals/UXPros_RealtimeFeedback.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 559, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsBig: file(
      relativePath: { eq: "wireframing/WireframesToMockups_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsSmall: file(
      relativePath: { eq: "wireframing/WireframesToMockups_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
